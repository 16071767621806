import * as React from 'react'

import './UpdateNotification.css'

export default function UpdateNotification() {
  const [shown, show] = React.useState(false)

  function onClick() {
    window.location.reload()
    show(false)
  }
  return (
    <div
      id="notification"
      className={shown ? 'shown' : 'hidden'}
      style={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        padding: '20px',
        maxWidth: '400px',
      }}
    >
      <div
        style={{
          padding: '20px',
          backgroundColor: '#323232',
          borderRadius: '4px',
          color: '#E4E4E4',
          fontFamily: 'sans-serif',
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <span>A new version is available.</span>
        <button
          onClick={onClick}
          style={{
            color: '#218455',
            border: 'none',
            backgroundColor: 'transparent',
            cursor: 'pointer',
          }}
        >
          UPDATE
        </button>
      </div>
    </div>
  )
}
