import React from 'react'
import { Link } from 'gatsby'
import './layout.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import { rhythm, scale } from '../utils/typography'
import UpdateNotification from './UpdateNotification'

const Layout = (props) => {
  const { location, title, children } = props
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  const isNotSubscribePage = location.pathname != '/subscribe/'

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header role="banner">
        {isNotSubscribePage && (
          <div className="top-banner">
            <p>
              Stay up to date with frontend technologies every other Friday with
              Robin's Gazette!
            </p>
            <a href="/subscribe">Subscribe&nbsp;→</a>
          </div>
        )}
        {header}
      </header>
      <main role="main">{children}</main>
      <footer role="contentinfo">
        © {new Date().getFullYear()} Robin Cussol, All Rights Reserved.
      </footer>
      <UpdateNotification />
    </div>
  )
}

export default Layout
