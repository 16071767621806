import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  contentType,
  articleDate,
  featuredImage,
  twitterCard = `summary`,
}) {
  const featuredImageMetas = (baseUrl) => {
    const result = []
    if (
      featuredImage &&
      featuredImage.image &&
      featuredImage.image.childImageSharp &&
      featuredImage.image.childImageSharp.fluid &&
      featuredImage.image.childImageSharp.fluid.src
    ) {
      result.push({
        name: `twitter:image`,
        content: baseUrl + featuredImage.image.childImageSharp.fluid.src,
      })
      result.push({
        name: `og:image`,
        content: baseUrl + featuredImage.image.childImageSharp.fluid.src,
      })
      result.push({
        name: `og:image:height`,
        content: 150,
      })
      result.push({
        name: `og:image:width`,
        content: 280,
      })
      result.push({
        property: `og:image`,
        content: baseUrl + featuredImage.image.childImageSharp.fluid.src,
      })
      result.push({
        property: `og:image:height`,
        content: 150,
      })
      result.push({
        property: `og:image:width`,
        content: 280,
      })
    }
    if (featuredImage.alt) {
      result.push({
        name: `twitter:image:alt`,
        content: featuredImage.alt,
      })
      result.push({
        name: `og:image:alt`,
        content: featuredImage.alt,
      })
      result.push({
        property: `og:image:alt`,
        content: featuredImage.alt,
      })
    }
    return result
  }

  const articleMetas =
    contentType === 'article' && articleDate
      ? [
          {
            name: `article:published_time`,
            content: articleDate,
          },
        ]
      : []

  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: contentType,
              },
              {
                name: `author`,
                content: 'Robin Cussol',
              },
              {
                name: `twitter:card`,
                content: twitterCard,
              },
              {
                name: `twitter:creator`,
                content: `@${data.site.siteMetadata.social.twitter}`,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              ...featuredImageMetas(data.site.siteMetadata.siteUrl),
              ...articleMetas,
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  articleDate: '',
  contentType: 'website',
  lang: `en`,
  meta: [],
  keywords: [],
  featuredImage: {
    alt: '',
    image: {
      childImageSharp: {
        fluid: {
          src: '',
        },
      },
    },
  },
}

SEO.propTypes = {
  articleDate: PropTypes.string,
  contentType: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  featuredImage: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    image: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string,
        }).isRequired,
      }).isRequired,
    }),
  }),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        social {
          twitter
        }
      }
    }
  }
`
